










































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { RctMyApplyViewModel } from '../viewmodels/rct-my-apply-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: RctMyApplyViewModel
  walletStore = walletStore
}
